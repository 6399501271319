.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.pointer:hover{
  cursor: pointer;
}
.pointer{
  cursor: pointer;
}

.color{
  color: #3158c2;
}
.image-cover{
  background-image: url("./images/loginform.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.image-overlay{
  background-size: cover;
  width: 100%;
  z-index: 100000;
  height: 100%;
  background-color: rgb(171 73 150 / 10%);
}

.image-overlay2{
  background-size: cover;
  width: 100%;
  z-index: 100000;
  height: 100%;
  background-color: rgb(171 73 150 / 20%);
}

.image-cover2{
  background-image: url("./images/invoiceback.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.without-hover-effect:hover{
  color: #000 !important;
}
.without-hover-effect{
  color: #000 !important;
}
.red-flag {
  color: rgb(255, 0, 98);
}

.card-shadow{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.fiche-divider{
  border-bottom: 3px solid rgb(171 173 180) !important;
}
.fiche-divider-top{
  border-top: 3px solid rgb(171 173 180) !important;
}
.border{
  height: 3px;
  background-color: rgb(171 173 180);
  border: 1px solid rgb(171 173 180) !important;
  border-radius:5px;
}

.ligne-height{
  line-height: 40px;
  font-size: 15px;
}
.description-text{
  font-size: 14px;
  font-weight: 500;
}
.ligne-height-25{
  line-height: 25px;
}
.height-50{
  height: 80px;
}
.medium{
  font-size: 20px;
}
.w-125px{
  width: 150px;
}
.w-90px{
  width: 90px;
}
.table-dark>:not(caption)>*>* {
  background-color: #141414 !important;
  color: #adadad;

}
.table-dark{
  background-color: #141414 !important;
  color: #adadad;
}
.total-input-light{
  color: #000 !important;
  background-color: #fff !important;
  font-weight: 800 !important;
}

.total-input-dark{
  color: #fff !important;
  background-color: #000 !important;
  font-weight: 800 !important;
}



.margin-05{
  margin-top: 0.8px;
}
.heigt-500px{
  height: 500px;
}
.custom-tooltip{
  background-color: rgba(255, 255, 255,0.7);
  border-radius: 5px;
  padding-right: 30px;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;

}

.float-right{
  float: right;
}

.pl-02{
  padding-left: 0.5rem!important;
}

.pr-02{
  padding-right: 0.5rem!important;
}

.hideen{
  width: 1px;
  height: 1px;
  visibility: hidden;
}